<template>
  <v-container
    :style="containerStyle"
    :class="instanceName + '-theme pa-0 fill-height'"
    fill-height
    fluid
  >
    <PasswordChange
      v-if="passwordExpired"
      v-model="passwordExpired"
    />
    <PasswordRecovery
      v-if="showPasswordRecoveryForm"
      v-model="showPasswordRecoveryForm"
    />
    <v-row
      align="center"
      justify="center"
      class="text-center fill-height"
    >
      <v-col
        v-if="logoUrl && logoUrl !== 'undefined' && showLeftLogoCol"
        cols="6"
        justify="center"
        class="fill-height text-center justify-self-center"
        :style="leftColStyle"
      >
        <img
          :src="logoUrl"
          class="mb-3 login-logo"
          style="z-index: 2; position: relative;"
        />
        <div class="bg-container">
          <v-img
            :src="theme.bgUrl"
            height="100%"
          />
        </div>
      </v-col>

      <v-col v-if="loadingUserInfo">
        <v-progress-circular
          :color="theme.themeColor"
          size="200"
          width="5"
          indeterminate
        />
      </v-col>

      <v-col v-else-if="redirectingBack && referrer && userIsLoggedIn">
        <v-progress-linear
          color="green lighten-1"
          height="15"
          indeterminate
        />
      </v-col>

      <v-col v-else-if="action === 'logout'">
        {{ $t('login.loggingOut') }} {{ userInfo && userInfo.name }} ...
        <br />
        <br />
        <v-progress-linear
          color="deep-purple lighten-5"
          height="15"
          indeterminate
        />
      </v-col>

      <v-col v-else-if="userIsLoggedIn">
        {{ $t('login.loggedInAs') }} {{ userInfo.name }}
        <br>
        <br>
        <v-btn
          :loading="loggingOut"
          type="submit"
          class="login-button portal-menu-color"
          block
          large
          dark
          @click="logout"
        >
          {{ $t('login.logoutButton') }}
        </v-btn>
      </v-col>

      <v-col
        v-else
        class="mb-5 text-center align-content-center"
        :cols="showLeftLogoCol ? 6 : 12"
      >
        <div v-if="testMode">
          <v-text-field
            v-model="testToken"
          />
          <v-btn @click="simulate">test</v-btn>
        </div>
        <v-form
          class="login-form"
          @submit.prevent="login"
        >
          <v-container
            :class="(theme.light ? 'light-rounded-form-container elevation-0' : '') + ' pa-0'"
            :style="loginFormContainerStyle"
            class="align-self-center"
          >
            <v-toolbar
              v-if="!theme.light"
              dark
              class="elevation-0"
              :color="themeColor"
            >
              <img
                v-if="theme.showLogoOnLoginBoxHeader"
                :src="theme.logoUrl"
                class="pr-5"
                style="height: 50px;"
              />
              <v-toolbar-title v-if="$vuetify.breakpoint.smAndUp">
                {{ portalNameTranslated }}
              </v-toolbar-title>
              <v-spacer/>
              <LoginSubmitButton
                v-if="!desktopViewBreakpoint"
                :in-toolbar="true"
              />
              <v-icon v-else class="pr-3">fa-user-lock</v-icon>
            </v-toolbar>
            <v-container>
              <v-row v-if="theme.light" class="pt-5">
                <v-col class="text-left light-form-title">
                  {{ portalNameTranslated }}
                </v-col>
              </v-row>
              <v-row
                offset-xs1
                py-5
                class="pa-3"
              >
                <v-col
                  v-if="desktopViewBreakpoint && !theme.light"
                  cols="12"
                  class="form-title pa-4"
                >
                  {{ $t('login.text') }}
                </v-col>
                <v-col
                  v-if="desktopViewBreakpoint || !askTFToken"
                  :cols="12"
                  class="px-5"
                >
                  <v-text-field
                    v-model="username"
                    :label="theme.light ? $t('login.username') : ''"
                    :placeholder="theme.light ? ' ' : $t('login.username')"
                    :disabled="askTFToken"
                    :background-color="askTFToken ? 'grey lighten-4' : ''"
                    :solo="!theme.light"
                    :single-line="!theme.light"
                    persistent-placeholder
                    tabindex="1"
                    append-icon="fa-user"
                    hide-details
                  />
                </v-col>

                <v-col
                  v-if="desktopViewBreakpoint || !askTFToken"
                  :cols="12"
                  class="px-5"
                >
                  <v-text-field
                    v-model="password"
                    :label="theme.light ? $t('login.password') : ''"
                    :placeholder="theme.light ? ' ' : $t('login.password')"
                    :disabled="askTFToken"
                    :background-color="askTFToken ? 'grey lighten-4' : ''"
                    :solo="!theme.light"
                    :single-line="!theme.light"
                    persistent-placeholder
                    tabindex="2"
                    hide-details
                    :type="showPassword ? 'text' : 'password'"

                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="() => {
                      showPassword = !showPassword
                    }"
                  />
                </v-col>

                <v-col
                  v-if="askTFToken && desktopViewBreakpoint"
                  cols="12"
                  class="form-title"
                >
                  {{ $t('login.enterTfToken') }}
                </v-col>

                <v-col
                  v-if="askTFToken"
                  cols="12"
                  class="px-5"
                >
                  <v-text-field
                    v-model="tfToken"
                    :label="theme.light ? $t('login.tfToken') : ''"
                    :placeholder="theme.light ? ' ' : $t('login.tfToken')"
                    :solo="!theme.light"
                    :single-line="!theme.light"
                    persistent-placeholder
                    append-icon="fa-key"
                    autofocus
                    hide-details
                  />
                </v-col>

                <v-col
                  v-if="desktopViewBreakpoint || theme.light"
                  cols="12"
                  class="px-5 pb-5 pt-7"
                >
                  <LoginSubmitButton />
                </v-col>
                <v-col
                  v-if="askTFToken"
                  cols="12"
                  class="px-5"
                >
                  <LoginCancelButton />
                </v-col>
              </v-row>
            </v-container>
          </v-container>
          <v-container
            class="text-xs-center mt-5"
          >
            <a
              v-if="$store.state.systemInfo.forgot_password_link"
              @click="showPasswordRecoveryForm = true"
            >
              {{ $t('login.forgotPasswordLink') }}
            </a>
          </v-container>

          <v-container
            class="text-xs-center"
          >
            <v-btn
              v-for="(language, index) in languages"
              :key="index"
              :outlined="locale === language"
              :color="theme.languageSelectButtonColor || 'grey lighten-1'"
              text
              fab
              small
              @click="changeLocale(language)"
            >
              {{ language }}
            </v-btn>
          </v-container>
          <v-container v-if="termsOfServiceUrl">
            <a :href="termsOfServiceUrl" target="_blank">
              Terms of Service
            </a>
          </v-container>
          <v-container v-if="googleAuthEnabled" class="text-center mt-5">
            <GoogleButton />
          </v-container>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import GoogleButton from './GoogleButton'
import LoginSubmitButton from './LoginSubmitButton'
import LoginCancelButton from './LoginCancelButton'
import PasswordRecovery from '@/components/PasswordRecovery'
import PasswordChange from '@/components/PasswordChange'
import queryString from 'query-string'
import loginMethods from './LoginMethods'
import {createHelpers} from 'vuex-map-fields'
import state from './../store/state'
import instanceThemes from '@/instanceThemes'
import getInstanceNameFromDomain from '@/helper/getInstanceNameFromDomain'
import i18nHelper from '@/locale/i18nHelper'
import sharedComputed from '@/sharedComputed'

const {mapFields} = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'LoginForm',

  components: {
    GoogleButton,
    PasswordChange,
    PasswordRecovery,
    LoginSubmitButton,
    LoginCancelButton
  },

  data () {
    return {
      showPassword: false,
    }
  },

  created () {
    this.$barcodeScanner.init(this.authenticateUsingToken)

    this.queryProps = queryString.parse(location.search)
    this.getPropsFromQuery()
    this.getPropsFromConfig()
    // Instance has been set now by getPropsFromConfig
    this.applyInstanceLocaleMessages()
    this.setPageTitle()


    const { dispatch } = this.$store
    dispatch("getSystemInfo").then(() => dispatch('initGoogleAuth'))

    if (this.action === 'logout') {
      // Log out
      dispatch('logoutUser').then(() => {
        // And back to portal if requested
        // Ex CargoMap and Cabin map do not
        // But Loader App supports offline and not-logged-in mode
        if (this.queryProps.returnBackAfterLogout) {
          dispatch('goBackToOriginPortal')
        } else {
          // Show login form when logged out
          this.action = 'login'
        }
      })
    } else {
      // Get user info
      dispatch('getUserInfo').then(() => {
        // If already logged in redirect back to portal
        if (this.userIsLoggedIn) {
          dispatch('goBackToOriginPortal')
        }
      })
    }
  },

  destroyed () {
    this.$barcodeScanner.destroy()
  },

  computed: {
    ...mapFields(Object.keys(state)),
    ...sharedComputed,

    googleAuthEnabled () {
      return !!this.systemInfo?.auth?.google?.key && this.googleAuth
    },

    termsOfServiceUrl () {
      if (!this.theme.termsPortalNameRegex.test(this.portalNameTranslated)) {
        return ''
      }
      return this.theme.termsOfServiceUrl
    },

    containerStyle () {
      let style = {}
      if (this.theme.bodyBgColor) {
        style.background = this.theme.bodyBgColor
      } else if (!this.theme.bgUrl &&this.logoBgColor && this.showLeftLogoCol) {
        style.background = 'linear-gradient(90deg, ' + this.logoBgColor + ' 50%, white 50%)'
      }
      return style
    },

    loginFormContainerStyle () {
      return {
        background: this.theme.loginBoxBgColor || '#EEEEEE',
        maxWidth: '460px', /* Min space for Finnlines case where logo needed next to title */
      }
    },

    leftColStyle () {
      let style = {
        position: 'relative',
      }
      if (this.logoBgColor) {
        style.background = this.logoBgColor
      }
      return style
    },

    desktopViewBreakpoint () {
      return this.$vuetify.breakpoint.smAndUp
    },

    showLeftLogoCol () {
      return this.$vuetify.breakpoint.mdAndUp
    },

    userIsLoggedIn () {
      return this.userInfo && this.userInfo.id
    },
  },

  watch: {
    locale (val) {
      this.$i18n.locale = val
    },
  },

  methods: {
    ...loginMethods,
    ...i18nHelper,

    applyInstanceLocaleMessages () {
      console.log(1, this.instanceName)
      this.setLocaleMessages(this.instanceName)
    },

    // Initially theme props were passed as query parameters
    getPropsFromQuery () {
      this.action = this.queryProps.action || this.action
      this.referrer = (this.queryProps.portalUrl && decodeURIComponent(this.queryProps.portalUrl)) || document.referrer
      this.logoUrl = this.queryProps.logoUrl && decodeURIComponent(this.queryProps.logoUrl)
      this.portalName = this.queryProps.portalName
      this.portalId = this.queryProps.portalId
      this.themeColor = this.queryProps.themeColor && decodeURIComponent(this.queryProps.themeColor) || this.themeColor
      this.logoBgColor = this.queryProps.logoBgColor && decodeURIComponent(this.queryProps.logoBgColor) || null
    },

    setPageTitle () {
      // Add portal name to page title
      document.title = this.portalNameTranslated
    },

    // Later theme props were put to login app instanceThemes file
    // Reason: metal instances have multiple portals and theme props were duplicated
    // Reason 2: theme props are directly related to login app design
    // Reason 3: In case in the future some changes are made, there is one place to configure login portal for all custom portals
    getPropsFromConfig () {
      // const referrerDomain = document.referrer && new URL(document.referrer).hostname
      this.instanceName = getInstanceNameFromDomain(instanceThemes) || 'aava'
      const theme = instanceThemes[this.instanceName] || {}
      this.theme = theme;
      ['logoUrl', 'logoBgColor', 'themeColor'].forEach(propName => {
        this[propName] = theme[propName] || this[propName]
      })
    },
  },
}
</script>

<style>
.theme--light.v-application {
  background: transparent;
}
.v-application a {
  color: #666 !important;
}
.form-title {
  font-size: 16px;
  padding: 10px 0 25px;
}
.login-logo {
  max-height: 400px;
  max-width: 60%;
  top: 50%;
  transform: translateY(-50%);
}
.bg-container {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  overflow: hidden;
}
.light-rounded-form-container {
  padding: 20px;
  border-radius: 10px !important;
  background: white !important;
}
.v-input__icon--append .v-icon {
  color: #ccc;
  font-size: 20px;
}
.light-form-title {
  font-size: 22px;
  padding-left: 33px !important;
  font-weight: 300;
}
.login-button {
  font-weight: 700;
}
</style>
